import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/video",
    name: "Video",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Video.vue")
  },
  {
    path: "/blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Blog.vue")
  },
  {
    path: "/news",
    name: "LatestNews",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LatestNews.vue")
  },
  {
    path: "/behind-the-chicken-shed",
    name: "BehindTheChickenShed",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BehindTheChickenShed.vue")
  },
  {
    path: "/useful-tips-and-ideas-for-sustainable-living",
    name: "UsefulTipsAndIdeas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UsefulTipsAndIdeas.vue")
  },
  {
    path: "/a-little-victory-every-time",
    name: "ALittleVictoryEveryTime",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/btcs/ALittleVictoryEveryTime.vue")
  },
  {
    path: "/what-is-normal-anyway",
    name: "WhatIsTheNewNormal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/btcs/WhatIsTheNewNormal.vue")
  },
  {
    path: "/you-have-a-voice",
    name: "YouHaveAVoice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tipsAndAdvice/YouHaveAVoice.vue")
  },
  {
    path: "/clothes-that-dont-kill-fish",
    name: "FinallyClothesThatDontKillFish",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tipsAndAdvice/FinallyClothesThatDontKillFish.vue")
  },
  {
    path: "/how-does-plastic-get-in-to-the-ocean-anyway",
    name: "HowDoesPlasticGetInToTheOceanAnyway",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tipsAndAdvice/HowDoesPlasticGetInToTheOceanAnyway.vue")
  },
  {
    path: "/we-are-all-streaming-but-where-does-this-stream-go",
    name: "WeAreAllStreaming",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tipsAndAdvice/WeAreAllStreaming.vue")
  }
];

const router = new VueRouter({
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { y: 0 };
  }
},
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
