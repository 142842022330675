<template>
  <div id="app">
    <div id="nav">
      <div>
        <b-navbar id="navBar" v-bind:class="{ 'color-nav': $route.path == '/' }" toggleable="lg" type="" variant="">
          <b-navbar-brand to="/">
            <img id="logo" src="./img/logo-keepusingit.png" class="d-inline-block align-top" alt="keepusingit.net logo">
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
            <b-navbar-nav>
              <b-nav-item class="menuItem" to="/"><span v-bind:class="{ 'active2': $route.path == '/' }">Home</span></b-nav-item>
              <b-nav-item class="menuItem" to="/about"><span v-bind:class="{ 'active2': $route.path == '/about' }">About</span></b-nav-item>
              <b-nav-item class="menuItem" to="/blog"><span v-bind:class="{ 'active2': $route.path == '/blog' }">Blog</span></b-nav-item>
              <b-nav-item class="menuItem" to="/news"><span v-bind:class="{ 'active2': $route.path == '/news' }">News</span></b-nav-item>
              <b-nav-item class="menuItem" to="/contact"><span v-bind:class="{ 'active2': $route.path == '/contact' }">Contact</span></b-nav-item>
            </b-navbar-nav>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <router-view />
    <footerSection/>
  </div>
</template>

<script>

import footerSection from '@/components/footerSection.vue';

export default {
  data() {
    return {
    }
  },
  components: {
    footerSection
  },
  mounted() {
      this.$nextTick(function(){
        window.addEventListener("scroll", function(){
          var navbar = document.getElementById("navBar");
          var nav_classes = navbar.classList;
          if(document.documentElement.scrollTop >= 150) {
            if (nav_classes.contains("shrink") === false) {
              nav_classes.toggle("shrink");
            }
          }
          else {
            if (nav_classes.contains("shrink") === true) {
              nav_classes.toggle("shrink");
            }
          }
        })
      })
    }
}

</script>

<style lang="scss">
* {
  transition: all 0.3s ease-in-out;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
  .textNarrow {
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .padTandB {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .color-nav {
    background-color: transparent !important;
  }
  #navBar {
    max-height: 100px;
    background-color: rgb(10, 10, 10);
  }
  .shrink {
    background-color: rgb(10, 10, 10) !important;
    max-height: 75px !important;
  }
  .page-content {
    text-align: left;
  }
  .homeSectionsPadding {
    padding: 80px 0 80px 0;
  }
  .anchorOffeset {
    position: relative;
    height: 1px;
    visibility: hidden;
    top: -70px;
    @media (max-width: 991px) {
      top: -65px;
    }
  }
  * {
    transition: all 0.3s ease-in-out;
  }
  a {
    &:hover {
      text-decoration: none;
      cursor: pointer;
      z-index: 1;
    }
  }
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2,h3,h4,h5,h6 {
    font-family: 'Playfair Display', serif;
    padding: 0 0 30px 0;
  }
  .hero-cta, .hero-cta2 {
    font-size: 22px;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    border-radius: 0;
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0;
    &:hover {
      background-color: #0098aa;
      border: 2px solid #0098aa;
      color: #fff;
    }
  }
  .hero-cta2 {
    color: #0098aa;
    border: 2px solid #0098aa;
    &:hover {
      color: #fff;
    }
  }
  .heroTitle {
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    padding-bottom: 40px;
    width: 740px;
    margin: auto;
    @media (max-width: 991px) {
      font-size: 33px;
      padding-bottom: 20px;
      width: auto;
    }
    @media (max-height: 400px) {
      font-size: 28px;
      padding-bottom: 0;
    }
  }
  #nav {
    position:fixed;
    z-index: 100;
    width: 100%;
    .navbar-toggler-icon {
     background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
    }
    #logo {
      width: 200px;
      @media (max-width: 300px) {
        width: 170px;
      }
    }
    a {
      color: #fff;
    }
    .menuItem {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
    span {
      padding: 9px 0px;
    }
    .active2 {
      border-bottom: 2px solid #2298AA;
    }
      .active:after, &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: relative;
        background: #2298AA;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .active:after {
        width: 136%;
        left: -18%;
        bottom: -0.625rem;
      }
      &:hover:after {
        width: 100%;
        left: 0;
      }
      @media (max-width: 991px) {
        width: 86px;
      }
    }
    @media (max-width: 991px) {
      text-align: left;
      .navbar-collapse {
        background-color: rgba(10,10,10,0.9);
        top: 0px;
        position: absolute;
        width: 100%;
        // height: 100vh;
        margin: 0;
        left: 0;
        z-index: -1;
        padding-top: 85px;
        padding-bottom: 35px;
      }
    }
  }
}
.carousel-caption {
  height: 100%;
}
.carousel-indicators {
    top: 90vh !important;
    bottom: auto !important;
}
.carousel-indicators li:focus {
    outline: none;
}
</style>
