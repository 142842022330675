<template>
  <div>
    <b-container fluid class="homeSectionsPadding">
      <b-row>
        <b-col>
          <h2>Plastic News</h2>
          <p class="textNarrow">We have gathered the latest plastic pollution news, from all the major news outlets. Visit our news page for the latest headlines.</p>
          <b-link to="/news" data-target="" class="nav-link hero-cta2">Latest news</b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>

</style>
