<template>
  <b-container fluid>
  <b-row>
    <b-col class="hero">
      <div>
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000"
          fade
          indicators
          :interval="10000"
          img-width="1024"
          img-height="547"
          >
          <div class="Vidoverlay">

          </div>
          <p class="att"><i>video by José Carlos Alexandre</i></p>
          <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
            <source src="../video/plastic-pollution-small.mp4" type="video/mp4">
          </video>

          <b-carousel-slide
            class="slide1"
            caption=""
            img-src=""
          >

            <div class="slideInner">
              <div class="slideInnerContents">
                <h1 class="heroTitle">Are you sick of single use plastic? wanna find ways to use less?</h1>
                <a @click="trigger" href="javascript:document.getElementById('about').scrollIntoView(true);" data-target="" class="nav-link hero-cta">Learn More</a>
              </div>
            </div>

          </b-carousel-slide>
          <b-carousel-slide
            class="slide2"
            caption=""
            img-src=""
          >
          <div class="slideInner">
            <div class="slideInnerContents">
              <h2 class="heroTitle">There are over 5 trillion pieces of plastic in our ocean</h2>
              <a href="https://theoceancleanup.com/" target="_blank" data-target="" class="nav-link hero-cta">Help with the cleanup</a>
            </div>
          </div>

          </b-carousel-slide>

        </b-carousel>
      </div>
    </b-col>
  </b-row>

</b-container>

</template>

<script>
export default {
  methods: {
  	trigger () {
    	document.documentElement.style.scrollBehavior = "smooth";
    }
  }
}
</script>


<style lang="scss" scoped>

.carousel-item {
  height: 100vh;
}

.hero {
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
  overflow: hidden;
  video {
    height: 100vh;
    left: 0;
    width: inherit;
    object-fit: fill;
  }
  .att {
    position: absolute;
    color: #fff;
    width: 100%;
    text-align: right;
    bottom: 0;
    right: 30px;
    font-size: 14px;
    @media (max-width: 767px) {
      right:auto;
      text-align: center;
    }
  }
  .Vidoverlay {
    background: rgba(37,	33,	31,0.4);
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .slide2 {
    background: url('../img/hero-turtle-chasing-plastic.jpg');
    background-size: cover;
    background-position: center;
  }
  .slideInner {
    position: relative;
    height: 100%;
    .slideInnerContents {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding-top: 73px;
    }
  }
}
</style>
