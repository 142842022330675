<template>
  <div class="">
  <b-container fluid>
    <hero></hero>
  </b-container>
  <b-container>
    <about> </about>
  </b-container>
  <b-container fluid>
    <infoDiv> </infoDiv>
  </b-container>
  <b-container id="blogSection" fluid>
    <b-container>
      <blog> </blog>
    </b-container>
  </b-container>
  </div>
</template>

<script>
import heroSection from '@/components/heroSection.vue';
import aboutSection from '@/components/aboutSection.vue';
import infoSection from '@/components/infoSection.vue';
import blogSection from '@/components/blogSection.vue';


export default {
  name: 'Home',
  components: {
    "hero": heroSection,
    "about": aboutSection,
    "infoDiv": infoSection,
    "blog": blogSection
  }
}
</script>

<style lang="scss" scoped>

</style>
