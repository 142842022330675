<template>
  <div class="footer">
    <b-container fluid>
      <footer class="text-muted">
        <div class="container">
          <span class="bTtMob"><a href="#"><b-icon icon="arrow-up" animation="cylon-vertical" font-scale="2"></b-icon></a></span>
          <!-- <p><a href="/">Terms and conditions</a> & <a href="/">Privacy policy</a></p> -->
          <span @click="trigger" class="bTt"><a href="javascript:document.getElementById('app').scrollIntoView(true);"><b-icon icon="arrow-up" animation="cylon-vertical" font-scale="2"></b-icon></a></span>
          <!-- GreenGeeks Seal Code -->
          <div class="greengeeks"><a href="#" v-on:click="ggs_ggseal()"><img src="https://static.greengeeks.com/ggseal/Green_12.png"></a></div>
          <!-- End GreenGeeks Seal Code -->
          <ul>
            <a href="https://www.facebook.com/keepusingit" target="_blank"><li class="facebook"></li></a>
            <a href="https://twitter.com/keepusingit" target="_blank"><li class="twitter"></li></a>
          </ul>
          <p>&copy; 2021 keepusingit.com | Website by <a class="greenBoots" href="https://www.greenbootsdigital.com/" target="_blank">Green Boots Digital</a></p>
        </div>
      </footer>
    </b-container>
  </div>

</template>

<script>
export default {
  methods: {
  	trigger () {
    	document.documentElement.style.scrollBehavior = "smooth";
    },
    ggs_ggseal: function() {
      window.open("https://my.greengeeks.com/seal/","_blank")
    }
  }
}
</script>


<style lang="scss" scoped>
  .footer {
    background-color: rgb(10,10,10);
    position: absolute;
    width: 100%;
    padding: 50px 0;
    ul {
      padding: 0;
      li {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
      .facebook {
        background: url('../img/icons/facebook-icon-w.png');
        background-size: cover;
        margin-right: 5px;
        &:hover {
          background: url('../img/icons/facebook-icon-b.png');
          background-size: cover;
        }
      }
      .twitter {
        background: url('../img/icons/twitter-icon-w.png');
        background-size: cover;
        margin-left: 5px;
        &:hover {
          background: url('../img/icons/twitter-icon-b.png');
          background-size: cover;
        }
      }
    }
    a, p {
      color: white;
      text-decoration: none;
    }
    a:hover {
      opacity: 0.8;
    }
    .bTt {
      position: absolute;
      width: 10%;
      text-align: right;
      right: 36px;
      svg {
        margin-top: 45%;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    .bTtMob {
      display:none;
      padding: 10px 0 30px 0;
      @media (max-width: 576px) {
        display:block;
      }
    }
    .greengeeks img{
      width: 80px;
      margin: auto;
      padding-bottom: 20px;
    }
    .greenBoots {
      color: #34B04A;
      font-weight: bold;
    }
  }
</style>
