<template>
  <div>
    <div class="anchorOffeset" id="about">
    </div>
    <b-container id="aboutSection" class="homeSectionsPadding" >
      <b-row>
        <b-col>
          <h2>Why keepusingit.com?</h2>
          <p class="textNarrow">Hi, my name is Jesse. I used to think I was pretty good at the whole environmentally friendly thing. I would recycle, try and avoid plastic, and say all the right things when the conversation of how to save the planet came up. Then I had kids. Since the birth of my son I have faced a tsunami of plastic products. Did you know a baby uses up to 3000 diapers in their first year? and did you know a diaper can take up to 400 years to decompose in a landfill??
          <br><br>All of our lives are so busy and so fast paced that single use products and unnecessary plastics have become the norm. So many of us want to do better but struggle to do so. I started keepusingit.com to have a conversation about plastic pollution, and find ways to use less plastic. I believe through talking, brainstorming and experimenting with alternatives to single use plastic, we can all become part of the solution instead of part of the problem.</p>
          <b-link @click="trigger" href="javascript:document.getElementById('info').scrollIntoView(true);" data-target="" class="nav-link hero-cta2">Let's get started</b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
export default {
  methods: {
  	trigger () {
    	document.documentElement.style.scrollBehavior = "smooth";
    }
  }
}
</script>


<style lang="scss" scoped>
  #aboutSection {
    min-height: 600px;
    position: relative;
    @media (max-height: 583px) {
      height: 600px;
    }
    @media (max-width: 767px) {
      height: auto;
    }
    .col {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        top: auto;
        left: auto;
        transform: translate(0%, 0%);
        position: relative;
      }
    }
  }
</style>
