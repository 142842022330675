<template>
  <div>
    <div class="anchorOffeset" id="info">
    </div>
      <b-container fluid id="animRow">
        <b-row>
          <b-col>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid id="infoInner">
        <b-container>
          <b-row>
            <b-col>
              <div class="vidOuter">
                <h2>The journey to plastic free has to start somewhere</h2>
                <p class="textNarrow">
                  So how can we reduce our plastic consumption? Well I certainly don't have all the answers, but I do have a desire to do better. It's kind of like a battle. Sometimes I make it through the day without buying anything made of plastic. Other days I don't do so good. The key is to get better at it, and help others do the same. I believe this is through sharing experiences, tips and advice with each other. So that is what we are going to do here through our blog. We want to
                  share our ideas with you and would love it if you would share yours with us.
                </p>
                <b-link to="/blog" data-target="" class="nav-link hero-cta">Read the blog</b-link>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>
#animRow {
  background: url('../img/bottle-in-water-edit.jpg');
  background-size: cover;
  background-position: 0 70%;
  height: 40vh;
}
#infoInner {
  background: #1c2f29;
  color: white;
  padding: 0 0 80px 0;
  .vidOuter {
    position: relative;
    top: -20px;
  }
  h2 {
    color: white;
    padding-bottom: 30px;
  }
}
</style>
